<template>
  <div >
    <div class="md-layout-item ">
      <md-tabs :md-active-tab="current_tab">
        <md-tab id="tab-documento" md-label="Documento"  >
          <md-card >
            <form novalidate @submit.prevent="validatePurchase">
              <md-card-header>
                <md-card-header-text v-if="!view">
                  <div v-if="!instance.id" class="md-title">Nueva movimiento</div>
                  <div v-else class="md-title">Editando movimiento </div>
                </md-card-header-text>
                <md-card-header-text v-else>
                 <div class="md-title">Ver movimiento</div>
                </md-card-header-text>
                <md-card-header-text>
                  <div class="md-title period">
                  <span v-if="!instance.id">
                    <span v-if="period_obj.id" >
                      {{ period_obj.date|to_text_date }}
                    </span>
                  </span>
                  <a v-if="!instance.id" href="#" @click.prevent="handler_open_select_period"
                  title="Elegir periodo">
                    <md-icon>call_made</md-icon></a>
                  <span v-if="instance.id" style="  text-transform: capitalize;">
                  {{instance.period_date|to_text_date}}</span>
                </div>
                </md-card-header-text>
                <md-menu md-size="big" md-direction="bottom-end">
                  <md-button class="md-icon-button"
                  @click="go_to('/movimientos_bancarios')">
                    <md-icon>keyboard_backspace</md-icon>
                  </md-button>
                </md-menu>
              </md-card-header>
              <md-card-content>
              <md-card-actions >
                <md-button v-if="!instance.id"
                type="submit" class="md-primary" :disabled="sending">Guardar</md-button>
                <md-button v-else
                type="submit" class="md-primary" :disabled="sending">Guardar cambios</md-button>
              </md-card-actions>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-medium-size-50 md-xsmall-size-100">
                    <div class="md-layout-item">
                      <md-field :class="getValidationClass('account')"
                        :title="instance.account_display">
                        <label for="account">Cuenta bancaria</label>
                        <md-select v-model="instance.account"
                        :disabled="sending || view"
                        >
                        <md-option :value="item.id" :title="item.full_name"
                          v-for="(item, key) in list_account.filter
                          ( item => item.id != instance.account_destination)"
                          :key="key">{{item.full_name}}</md-option>
                        </md-select>
                      <span class="md-error"
                          v-if="!$v.instance.account.required">
                          La cuenta bancaria es requerida</span>
                    </md-field>
                    </div>
                  </div>
                  <div class="md-layout-item md-large-size-33
                  md-medium-size-25 md-xsmall-size-100" >
                    <md-field :class="getValidationClass('date_movement')" class="md-focused">
                      <label for="date_movement" >Fecha movimiento</label>
                      <md-input type="date" name="date_movement" v-on:blur="check_date"
                      id="date_movement" v-model="instance.date_movement"
                      :disabled="sending || view" />
                      <span class="md-error"
                        v-if="!$v.instance.date_movement.required">
                        La fecha del movimiento es requerida</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-medium-size-25 md-xsmall-size-100" >
                    <md-field >
                      <label for="reference_number">Número de referencia</label>
                      <md-input name="reference_number"
                        v-model="instance.reference_number" :disabled="sending || view" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-medium-size-50 md-xsmall-size-100">
                    <md-field :class="getValidationClass('document_number')">
                      <label for="numero">
                        <span v-if="instance.document_type != 'C'"> Numero documento</span>
                        <span v-if="instance.document_type === 'C'"> Numero cheque</span>
                      </label>
                      <md-input name="numero"
                      id="document_number" v-model="instance.document_number"
                      :disabled="sending || view" />
                      <span class="md-error"
                        v-if="!$v.instance.document_number.required">
                          <p v-if="instance.document_type != 'C'">
                          El número de documento es requerida</p>
                          <p v-if="instance.document_type === 'C'">
                          El número de cheque es requerida</p>
                        </span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-medium-size-25 md-xsmall-size-100">
                    <div class="md-layout-item" >
                      <md-field :title="instance.document_type_display"
                      :class="getValidationClass('document_type')">
                        <label for="document_type">Tipo documento</label>
                        <md-select v-model="instance.document_type"
                          name="movie"
                          id="movie"
                          :disabled="sending ||  view"
                        >
                        <md-option :value="item.value" :title="item.text"
                          v-for="(item, key) in choosen_type_op"
                          :key="key">{{item.text}}</md-option>
                        </md-select>
                      <span class="md-error"
                          v-if="!$v.instance.document_type.required">
                          El tipo de documento es requerido</span>
                    </md-field>
                    </div>
                  </div>
                  <div class="md-layout-item md-medium-size-25 md-xsmall-size-100">
                    <md-field :class="getValidationClass('amount')">
                      <label for="amount">Monto</label>
                      <md-input type="number"
                        @focus="$event.target.select()"
                        v-model="instance.amount" :disabled="sending || view" />
                      <span class="md-error"
                          v-if="!$v.instance.amount.required">
                          El monto es requerido</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-medium-size-50 md-xsmall-size-100">
                    <md-field :class="getValidationClass('concept')">
                      <label for="concept">
                        <span v-if="instance.document_type != 'C'"> Concepto</span>
                        <span v-if="instance.document_type === 'C'"> Pagar a</span>
                      </label>
                      <md-input type="text" id="concept" name="concept"
                        @focus="$event.target.select()"
                        v-model="instance.concept" :disabled="sending || view" />
                      <span class="md-error"
                          v-if="!$v.instance.concept.required">
                          <p v-if="instance.document_type === 'C'">Debe ingresar un valor</p>
                          <p v-if="instance.document_type != 'C'">El concepto es requerido</p>
                      </span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-medium-size-50 md-xsmall-size-100"
                  v-if="instance.document_type === 'R'">
                    <div class="md-layout-item">
                      <md-field
                        :title="instance.account_display">
                        <label for="account_destination">Cuenta bancaria de destino
                          <router-link target="_blank"
                          v-if="instance.id && instance.transfer_destino"
                          :to="'/form_movimientos_bancarios_view/'+instance.transfer_destino"
                          @click.prevent="handler_open_select_period">
                          <md-icon>call_made</md-icon></router-link></label>
                        <md-select v-model="instance.account_destination"
                          :disabled="sending || view"
                        >
                        <md-option :value="item.id" :title="item.full_name"
                          v-for="(item, key) in list_account.filter
                          ( item => item.id != instance.account)"
                          :key="key">{{item.full_name}}</md-option>
                        </md-select>
                    </md-field>
                    </div>
                  </div>
                </div>
              </md-card-content>
              <md-progress-bar md-mode="indeterminate" v-if="sending" />
              <md-button v-if="!instance.id"
              type="submit" class="md-primary" :disabled="sending">Guardar</md-button>
              <md-button v-else
              type="submit" class="md-primary" :disabled="sending">Guardar cambios</md-button>
            </form>
          </md-card>
        </md-tab>
        <md-tab id="tab-partida" md-label="Partida"
            :md-disabled="tab" >
            <md-card-header>
              <md-card-header-text>
                <h3 for="total" >
                Total Documento: {{ this.instance.amount|format_number }}</h3>
              </md-card-header-text>
              <md-card-header-text >
                Número partida:
                <span class="headline" v-if="instance.partida_obj">
                 {{instance.partida_obj.numero_partida}} </span>
              </md-card-header-text>
              <md-card-header-text>
                Fecha partida:
                <span v-if="instance.partida_obj">
                {{instance.partida_obj.date_partida|to_date }} </span>
              </md-card-header-text>
              <md-menu md-size="big" md-direction="bottom-end">
                <br>
                <md-button class="md-icon-button"
                  @click="go_to('/movimientos_bancarios')">
                  <md-icon>keyboard_backspace</md-icon>
                </md-button>
              </md-menu>
            </md-card-header>

            <div v-if="instance.partida">
              <basePartida :id_partida = "instance.partida" :view = "!view"
              v-if="is_show_component"/>
            </div>

              <div class="md-layout-item md-small-size-50" >
                <md-field v-if="hidden">
                  <md-input name="id"
                    id="id"
                    v-model="instance.partida" :disabled="sending" />
                </md-field>
              </div>
               <div class="moveRigth" v-if="!view">
                <md-button class="md-primary"
                  @click="confirmPartida()" v-if="instance.id">
                  Guardar Partida
                </md-button>
                <md-button class="md-primary" @click="confirmPartida()" v-else>
                  Guardar Cambios
                </md-button>
              </div>
              <div class="md-layout-item md-small-size-100" v-if="instance.partida">
                <md-field class = "margin">
                  <label for="descripcion_partida">Descripción partida</label>
                    <md-input name="descripcion_partida"
                      id="descripcion_partida"
                      v-model="instance.partida_obj.descripcion" :disabled="sending || view" />
                </md-field>
              </div>
        </md-tab>
      </md-tabs>
      <br>
      <br>
      <md-dialog :md-active.sync="showDialog_period">
        <md-dialog-title>Elegir periodo</md-dialog-title>
        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field >
                <label for="period">Año fiscal</label>
                <md-select v-model="filter_period_obj.fiscal_year"
                @md-selected="get_list_period(true)">
                <md-option value="">----</md-option>
                <md-option :value="item.id"
                  v-for="(item, key) in list_years"
                  :key="key">{{item.year}}</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="period">Periodos </label>
                <md-select v-model="filter_period_obj.period_id"
                @md-selected="set_period">
                <md-option value="">----</md-option>
                <md-option :value="item.id"
                  v-for="(item, key) in list_periods"
                  :key="key">{{item.period}}</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-dialog-actions>
          <md-button class="md-primary" @click="showDialog_period = false">Cerrar</md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>
    <md-snackbar
      :md-active.sync="purchaseSaved">El documento {{ document }} ha sido actualizado!</md-snackbar>
    <md-snackbar
      :md-active.sync="partidaSaved">La partida ha sido actualizada!</md-snackbar>
    <md-snackbar
      :md-active.sync="periodSelected">se ha elegido un periodo!</md-snackbar>
  </div>
</template>
<script>
/* eslint-disable */
import Swal from 'sweetalert2';
import moment from 'moment';
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
} from 'vuelidate/lib/validators'

export default {
  name: 'Formulario_movimientos_bancarios',
  created(){
    if (this.$route.params.id){
      this.instance.id = this.$route.params.id
      this.retrievePurchase();
    }
    if (this.$route.params.idview) {
      this.instance.id = this.$route.params.idview
      this.view = true
      this.retrievePurchase();
    }
    const period_obj_temp = this.get_data_storage_plane('periodBankObj');
    if (period_obj_temp) {
      this.period_obj = period_obj_temp;
      this.filter_period_obj = period_obj_temp;
      this.filter_period_obj.period_id = period_obj_temp.id
    }
    const yearSelected = this.get_data_storage_raw('id_fiscal_year');
    if (yearSelected) {
      if(!this.period_obj.fiscal_year){
        this.period_obj.fiscal_year = yearSelected;
      }
    }

    // this.retrievePeriod();
    this.get_list_accounts();

    this.get_data_storage('book_diary').then((d) => {
      this.book_diary = d.id;
      if (this.book_diary) {
        this.$store.dispatch('set_book_diary', { bookDiary: d });
      }
    }).catch((error) => {
      Swal.fire({
        title: "Aviso",
        text: "Debe de ingresar libro diario, para crear partidas",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push('/libro_diario');
        }
      });
    });
  },
  mixins: [validationMixin],
  components: {
    basePartida: () => import('../components/BasePartida.vue'),
  },
  data() {
    return {
      list_provider: [],
      list_rubros: [],
      list_periods:[],
      list_years:[],
      list_store: [],
      list_account: [],
      book_diary: {
        id: null
      },
      period_obj: {},
      filter_period_obj: {},
      mes: '',
      base: process.env.VUE_APP_BASE_URL,
      instance: {
        date_movement: null,
        document_number: null,
        document_type: null,
        amount: null,
        account_name: null,
        account: null,
        crear_partida: true,
        date_partida: null,
        concept: null,
        numero_partida: "1",
        tipo_partida: "CXP",
        book_diary: null,
        id: null,
        partida_obj: {},
      },
      edit: false,
      view: false,
      is_show_component: true,
      url: 'movimientos_bancarios/',
      url_provider: 'providers/',
      url_rubros: 'rubros/',
      url_stores: 'stores/',
      url_accounts: 'accounting_account/',
      url_diarybook: 'diary_book_detail/',
      url_partida: 'partida_detail/',
      url_period: 'periods/',
      url_account_bank: 'account_bank/',
      url_fiscal_year: 'fiscal_year',
      purchaseSaved: false,
      sending: false,
      document: null,
      showDialog_period: false,
      value: null,
      totalDebe: 0.00,
      totalHaber: 0.00,
      diferencia: 0.00,
      rowsPerPage: 10,
      current_tab: "tab-documento",
      hidden: false,
      tab: true,
      partidaSaved: false,
      periodSelected: false,
      choosen_type_op: [
        {value:'D', text: 'DEPOSITO'},
        {value:'E', text: 'RETIRO'},
        {value:'C', text: 'CHEQUE'},
        {value:'R', text: 'TRANSFERENCIA'},
        {value:'F', text: 'CXC'},
        {value:'P', text: 'CXP'},
      ],
    };
  },
  validations: {
    instance: {
      date_movement: {
        required,
      },
      document_number: {
        required,
      },
      document_type: {
        required,
      },
      amount: {
        required,
      },
      account: {
        required,
      },
      concept: {
        required,
      },
    },
  },
  methods: {
    async set_period(period_id) {
      this.period_obj = {}
      this.period_obj = this.list_periods.find( item => item.id === period_id )
      if (this.period_obj){
        localStorage.removeItem('periodBankObj');
        this.save_storage('periodBankObj', this.period_obj);
        this.periodSelected = true
        this.showDialog_period = false
      }
    },
    handler_open_select_period() {
      if (this.list_years.length === 0){
        this.get_list_years();
      }
      if (this.list_periods.length === 0){
        this.get_list_period()
      }
      this.showDialog_period = true
    },
    get_list_period(set_null=false) {
      const payload = {};
      const self = this;
      if (set_null){
        this.filter_period_obj.period_id = null;
      }
      if (!this.filter_period_obj.fiscal_year) return false
      payload.fiscal_year = this.filter_period_obj.fiscal_year
      self.sendRequest_with_url('periods/', payload).then((data) => {
        self.list_periods = data.data;
        self.sending_request = false
      }).catch((error) => {
        self.sending_request = false
        self.handlerError(error)
      });
    },
    get_list_years() {
      const payload = {};
      const self = this;
      this.sendRequest_with_url(this.url_fiscal_year, payload).then((data) => {
        self.sending_request = false;
        self.list_years = data.data;
      }).catch((error) => {
        self.sending_request = false;
        self.handlerError(error);
      });
      return null;
    },
    empty(select){
      if (!select) {
        this.instance.amount = 0.00
      }
    },
    reset_component () {
      this.is_show_component = false
      this.$nextTick(()=> {
        this.is_show_component = true
      })
    },
    set_account(obj) {  
      this.instance.account_name = obj.name;
      this.instance.account = obj.id;
    },
    get_list_accounts(searchTerm) {
      const payload = {};
      payload.status = "True"
      payload.company = this.$store.state.company_obj.id

      const self = this;
      this.sendRequest(payload, this.url_account_bank).then((data) => {
        self.list_account = data.data;
      }).catch((error) => {
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    go_to(url) {
      this.$router.push(url);
    },
    get_data_storage_raw(name) {
      return localStorage.getItem(name);
    },
    save_storage_raw(key, data) {
      localStorage.setItem([key], data);
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    async get_data_storage(name) {
      return await JSON.parse(localStorage.getItem(name));
    },
    get_data_storage_plane(name) {
      return JSON.parse(localStorage.getItem(name));
    },
    handlerError: (error) => {
      const result = error.response.data;
      const self = this;
      if (typeof (error.response.data) === 'object') {
        Object.entries(result).forEach(([key, value]) => { window.$swal.fire('Error!', `${key}: ${value}`, 'error'); });
        return false;
      }
      window.$swal.fire('Error!', result, 'error');
      return false;
    },
    set_provider (obj) {
      this.instance.provider_name = obj.name
      this.instance.provider = obj.id
      //this.get_document()
    },
    get_list_stores(searchTerm=null) {
      const payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
      }
      filter: "True"
      const self = this;
      this.sendRequest(payload, this.url_stores).then((data) => {
        self.list_store = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    getValidationClass (fieldName) {
      const field = this.$v.instance[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm () {
      this.$v.$reset()
    },
    validatePurchase () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saveOrUpdate()
      }
    },
    check_date(){
      if (!this.period_obj.date) {
        this.$swal.fire('Error!', 'Es necesario elegir un periodo.', 'error')
        return false
      }

      const self = this
      let date_i = moment(self.period_obj.date)
      if (this.instance.id){
        date_i = moment(this.instance.period_date)
      }
      let date_f = moment(date_i).subtract(3, 'months').format()
      let date_fin = moment(date_f).subtract(1, 'days').format()
      let fecha = moment(self.instance.date_movement).isBetween(date_fin, date_i, 'days')
      let dif = date_i.diff(self.instance.date_movement, 'days')
      
      if (fecha === false && dif >= 1){
        self.$swal.fire('Error!', 'Fecha del movimiento es mayor a 3 meses.', 'error')
        return false
      }
      if (fecha === false && dif <= -31){
        self.$swal.fire('Error!', 'Fecha del movimiento es de un mes posterior.', 'error')
        return false
      }else if (isNaN(dif)){
        self.$swal.fire('Error!', 'Ingreso una fecha errónea.', 'error')
        return false
      }
    },
    saveOrUpdate () {
      if (!this.instance.document_number) {
        this.$swal.fire('Error!', 'Numero de documento.', 'error')
        return false
      }
      if (!this.instance.date_movement) {
        this.$swal.fire('Error!', 'Fecha es requerida.', 'error')
        return false
      }
      if (!this.period_obj.date) {
        this.$swal.fire('Error!', 'Es necesario elegir un periodo.', 'error')
        return false
      }
      const self = this
      let date_i = moment(self.period_obj.date)
      if (this.instance.id){
        date_i = moment(this.instance.period_date)
      }
      let date_f = moment(date_i).subtract(2, 'months').format()
      let date_fin = moment(date_f).subtract(1, 'days').format()
      let fecha = moment(self.instance.date_movement).isBetween(date_fin, date_i, 'days')
      let dif = date_i.diff(self.instance.date_movement, 'days')
      if (fecha === false && dif >= 1){
        self.$swal.fire('Error!', 'Fecha del movimiento es mayor a 2 meses.', 'error')
        return false
      }
      if (fecha === false && dif <= -31){
        self.$swal.fire('Error!', 'Fecha del movimiento es de un mes posterior.', 'error')
        return false
      }else if (isNaN(dif)){
        self.$swal.fire('Error!', 'Ingreso una fecha errónea.', 'error')
        return false
      }

      const payload = self.instance
      
      this.sending = true      
      if (dif < 1){
        payload.date_partida = this.instance.date_movement
      }
      if (dif > 1){
        payload.date_partida = self.period_obj.date
        if (this.instance.id){
          payload.date_partida = this.instance.date_partida
        }
      }
      if (this.instance.id) {
        this.putRequest(payload).then(function (data) {
          // self.document = `${self.instance.serie}-${self.instance.number_invoice_purcharse}`
          self.purchaseSaved = true
          self.sending = false
          self.instance = data.data
          self.reset_component()
        }).catch(function (error) {
          self.sending = false
          if (typeof (error.response.data) === 'object') {
            const result = error.response.data
            Object.entries(result).forEach(
              ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
            )
            return false
          }
          self.$swal.fire('Error!', error.response.data, 'error')
        })
        return
      }
      payload.period = self.period_obj.id
      payload.book_diary = self.$store.state.book_diary.id
      payload.date_i = self.period_obj.date
      
      payload.company = this.$store.state.company_obj.id
      payload.store = this.$store.state.store_obj.id
      payload.office = localStorage.getItem('office')
      
      this.postRequest(payload).then(function (data) {
        self.purchaseSaved = true
        self.sending = false
        self.clearForm()
        self.instance = data.data
        if (self.instance.partida){
          self.tab = false
        }
        self.current_tab = "tab-partida"
      }).catch(function (error) {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
      })
    },
    confirmPartida() {
      const self = this;
      if (parseFloat(this.diferencia) >= 0.01) {
        self.$swal.fire('Error!', 'Tiene diferencia en la partida.', 'error')
        return false
      }else{
        const payload = this.instance.partida_obj
        const self = this
        this.sending = true
        if (payload.id) {
          this.putRequestEncabezadoPartida(payload).then(function (data) {
            self.partidaSaved = true
            self.sending = false
            self.current_tab = "tab-documento"
            self.tab = true
          }).catch(function (error) {
            self.sending = false
            if (typeof (error.response.data) === 'object') {
              const result = error.response.data
              Object.entries(result).forEach(
                ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
              )
              return false
            }
            self.$swal.fire('Error!', error.response.data, 'error')
          })
          return
        }

      }
    },
    async retrievePurchase () {
      const self = this
      const payload = {
        id: this.instance.id,
      }
      await this.retrieveRequest(this.url, payload).then(function (data) {
        self.instance = data.data
        if (self.instance.partida){
          self.tab = false
        }
      }).catch(function (error) {
        if (!error.response) {
          self.$swal.fire('Error!', error, 'error')
          return false
        }
        self.handlerError(error)
      })
    },
    async sendRequest(payload, url) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async postRequest (payload) {
      const result = await this.$http.post(this.url, payload)
      return result
    },
    async putRequest (payload) {
      const result = await this.$http.put(`${this.url}${payload.id}/`, payload)
      return result
    },
    async putRequestEncabezadoPartida (payload) {
      const result = await this.$http.put(`${this.url_diarybook}${payload.id}/`, payload)
      return result
    },
    async sendRequest_with_url(url, payload) {
      // const result = await this.$http.get(this.url, payload);
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async retrieveRequest (url, _payload) {
      const result = await this.$http.get(url + _payload.id + '/', { params: _payload })
      return result
    },
    async retrieveRequestPeriod (url_period, _payload) {
      const result = await this.$http.get(url_period + _payload.id + '/', { params: _payload })
      return result
    },
    get_document(){
      const self = this
      if (self.instance.date_movement && self.instance.serie
        && self.instance.document_number){
          self.verify_document()
        }
    },
    verify_document(){
      if (this.instance.id) return false
      const self = this
      const payload = {
        verify: true,
        date_movement: this.instance.date_movement,
        serie: this.instance.serie,
        document_number: this.instance.document_number,
        company: this.$store.state.company_obj.id,
        provider: this.instance.provider,
      }
      this.postRequest(payload).then(function (data) {

      }).catch(function (error) {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
        return false
      })
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    },
    format_number: function (value) {
      if (!value) return ''
        return isNaN(value) ? 0 : parseFloat(value).toFixed(2)
    },
    to_date(value) {
      if (!value ) return '-----'
      return moment(value, 'YYYY-MM-DD', 'es').format('DD/MM/YYYY')
    },
    to_text_date(value) {
      if (!value ) return '-----'
      return moment(value, 'YYYY-MM-DD', 'es').format('MMMM Y')
    },
  },
  computed: {
    settings_obj() {
      return this.$store.state.settings_obj;
    },
  },
};

</script>
<style lang="css" scoped>
 .success {
    font-weight: 500;
    background-color: #4aad09;
  }
  .warning {
    font-weight: 500;
    background-color: yellow;
  }
  .danger {
    font-weight: 500;
    background-color: red;
  }
  table {
    font-size: 2em;
  }
  .customWidthClass{
    width: 10%;
  }
  .moveRigth{
    display: block;
    float: right;
    margin-right: 20%;
    margin-top: 2%;
  }
  .period{
    float: center;
  }
  .moveLeft{
    display: block;
    float: left;
  }
  .size{
    width: 150px;
    margin-left: 20px;
  }
  .margin{
    margin-bottom: 40px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #424242 inset;
  }
  
  .md-menu-content {
    width: auto  !important;
  }

</style>
